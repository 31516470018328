import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <p>{`Hi, I'm Adam! I graduated with a BA in Mathematics from Western Washington University, and am currently enrolled in the MSEE program at the University of Colorado. I LOVE learning, and that's what attracted me to Embedded Software Engineering; technology is developing at such a rapid rate, I'll never run out of new topics to learn.
The first part of my career was spent programming, generating, and presenting actuarial valuations for unions and multi-employer pension plans in New England. From that point on, I've spent the majority of my career helping to implement benefit administration systems for companies across the United States.`}</p>
    <p>{`My passion is learning, and in particular, I'm interested in exploring the use of cutting-edge technology to integrate with and enrich our everyday lives. The Masters program I'm currently enrolled in has allowed me to explore this passion by discovering the development of Embedded Systems Software, Firmware, and Power Electronic circuitry.`}</p>
    <h3>{`Here is a list of classes I've taken thus far:`}</h3>
    <ul>
      <li parentName="ul"><font size="3">ECEA 5340 Sensors and Sensor Circuit Design (0.8 credits)</font>  </li>
      <li parentName="ul"><font size="3">ECEA 5341 Motors and Motor Control Circuits (0.8 credits)</font>  </li>
      <li parentName="ul"><font size="3">ECEA 5342 Pressure, Force, Motion, and Humidity Sensors (0.8 credits)</font>  </li>
      <li parentName="ul"><font size="3">ECEA 5343 Sensor Manufacturing and Process Control (0.6 credits)</font>  </li>
      <li parentName="ul"><font size="3">ECEA 5360 Introduction to FPGA Design for Embedded Systems (0.8 credits)</font>  </li>
      <li parentName="ul"><font size="3">ECEA 5361 Hardware Description Languages for FPGA Design (0.8 credits)</font> </li>
      <li parentName="ul"><font size="3">ECEA 5385 Industrial IoT Markets and Security (1 credit)</font>  </li>
      <li parentName="ul"><font size="3">ECEA 5386 Project Planning and Machine Learning (1 credit)</font>  </li>
      <li parentName="ul"><font size="3">ECEA 5387 Modeling and Debugging Embedded Systems (1 credit)</font>  </li>
      <li parentName="ul"><font size="3">ECEA 5315 Concept and Practices (0.6 credits)</font>  </li>
      <li parentName="ul"><font size="3">ECEA 5316 Theory and Analysis (0.8 credits)</font>  </li>
      <li parentName="ul"><font size="3">ECEA 5317 Mission-Critical, SW Applications (0.8 credits)</font>  </li>
      <li parentName="ul"><font size="3">ECEA 5318 Real-Time Embedded Systems Project (0.8 credits)</font>  </li>
      <li parentName="ul"><font size="3">ECEA 5346 User Experience Interface Design for Embedded Systems (1 credit)</font>  </li>
      <li parentName="ul"><font size="3">ECEA 5347 Rapid Prototyping of Embedded Interface Designs (1 credit)</font>  </li>
      <li parentName="ul"><font size="3">ECEA 5348 M2M and IoT Interface Design and Protocols (1 credit)</font>  </li>
      <li parentName="ul"><font size="3">ECEA 5700 Introduction to Power Electronics (0.8 credits)</font>  </li>
      <li parentName="ul"><font size="3">ECEA 5701 Converter Circuits (1 credit)</font>  </li>
      <li parentName="ul"><font size="3">ECEA 5702 Converter Control (1.2 credits)</font>    </li>
      <li parentName="ul"><font size="3">ECEA 5730 Introduction to Battery-Management Systems (0.8 credits)</font></li>
      <li parentName="ul"><font size="3">ECEA 5703 Magnetics Design (1 credit)</font></li>
      <li parentName="ul"><font size="3">ECEA 5362 FPGA Softcore Processors and IP Acquisition (0.8 credits)</font>  </li>
      <li parentName="ul"><font size="3">ECEA 5731 Equivalent Circuit Cell Model Simulation (0.8 credits)</font>  </li>
      <li parentName="ul"><font size="3">ECEA 5732 Battery State-of-Charge (SOC) Estimation (1 credit)</font>  </li>
      <li parentName="ul"><font size="3">ECEA 5733 Battery State-of-Health (SOH) Estimation (0.8 credits)</font>  </li>
      <li parentName="ul"><font size="3">ECEA 5734 Battery Pack Balancing and Power Estimation (0.8 credits)</font>  </li>
      <li parentName="ul"><font size="3">ECEA 5305 Linux System Programming and Introduction to Buildroot (1 credit)</font></li>
      <li parentName="ul"><font size="3">DTSA 5501 Algorithms for Searching Sorting and Indexing (1 credit)</font> </li>
    </ul>
    <h3>{`And here's what's on the horizon:`}</h3>
    <ul>
      <li parentName="ul"><font size="3">DTSA 5502 Trees and Graphs - Basics (1 credit)</font></li>
      <li parentName="ul"><font size="3">DTSA 5503 Dynamic Programming and Greedy Algorithms (1 credit)</font></li>
      <li parentName="ul"><font size="3">DTSA 5507 Fundamentals of Software Architecture for Big Data (1 credit)</font></li>
      <li parentName="ul"><font size="3">DTSA 5509 Introduction to Machine Learning - Supervised Learning (1 credit)</font> </li>
      <li parentName="ul"><font size="3">DTSA 5510 Unsupervised Algorithms in Machine Learning (1 credit)</font>  </li>
      <li parentName="ul"><font size="3">DTSA 5511 Introduction to Deep Learning (1 credit)</font> </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      