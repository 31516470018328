import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Get in touch`}</h2>
    <p>{`Shoot me a note or connect with me on other platforms: `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/adam-miyata-0b1153164/"
      }}>{`LinkedIn`}</a>{` | `}<a parentName="p" {...{
        "href": "https://www.https://github.com/akmiyata"
      }}>{`GitHub`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      