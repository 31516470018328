import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Embedded Systems Projects - UC Boulder`}</h2>
    <ProjectCard title="Real Time Embedded Systems Final Project" link="https://github.com/akmiyata/UCB-RTES-Project" bg="linear-gradient(to right, #373b44 0%, #4286f4 100%)" mdxType="ProjectCard">Real time (rate monotonic) analysis was used on a Raspberry Pi to synchronize webcam photo capture with a digital stopwatch, accurate to 1/10th of a second.
    </ProjectCard>
    <ProjectCard title="M2M and IoT Interface Design" link="https://github.com/akmiyata/UCB-Pseudosensor-Projects" bg="linear-gradient(to right, #000000 0%, #ffffff 100%)" mdxType="ProjectCard">
A series of projects demonstrating server-client interfaces using different technologies, up to and including Amazon Web Services
    </ProjectCard>
    <ProjectCard title="Coming soon!" link="https://github.com/akmiyata/Brushless-DC-Motor-Controller" bg="linear-gradient(to right, #000000 0%, #ffffff 100%)" mdxType="ProjectCard">
As time allows, I'm working on a few projects using the TIVA TM4C123 board, and will have plenty of additional projects from my final year of coursework. Check back often!
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      